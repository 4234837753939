import { DisplayViewData } from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/displayViews.model'
import USBButton from '@usb-shield/react-button'
import { USBColumn } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import styles from '@/components/BlockArrayWrapper/TextBlockWithImage/DisplayViews/styles/oneColStacked.module.scss'

const threeUpContentBlock: Object = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 4,
    large: 4,
    medium: 4,
    small: 4,
  },
}
const fourUpContentBlock: any = {
  ...defaultColLayoutOptions,
  spans: {
    xlarge: 3,
    large: 3,
    medium: 4,
    small: 4,
  },
}

const OneColStacked = ({
  title,
  shortDescription,
  ctaText,
  ctaLink,
  image,
  displayView,
  linkAsButton,
  levelDownHeads,
  fourUp,
}: DisplayViewData) => {
  const contentBlock = fourUp ? fourUpContentBlock : threeUpContentBlock

  function GetLink() {
    if (ctaLink === '' || !ctaLink) {
      return false
    }

    if (linkAsButton) {
      return (
        <USBButton
          variant="primary"
          size="default"
          addClasses={styles.ctaButton}
          handleClick={() => {
            window.open(ctaLink, '_self')
          }}
        >
          {ctaText}
        </USBButton>
      )
    }
    const ctaTextTrimmed =
      ctaText?.length > 350 ? `${ctaText.substring(0, 350)}` : ctaText
    return (
      <div className={styles.linkLayout}>
        <USBLink
          linkType="basic"
          href={ctaLink}
          addClasses={styles.ctaLink + ' ' + styles.solutionAreaLink}
        >
          {ctaTextTrimmed}
        </USBLink>
      </div>
    )
  }

  return (
    <>
      <USBColumn layoutOpts={contentBlock} addClasses={styles.responsiveColumn}>
        {image && (
          <div className={styles.imageWrapper}>
            <img src={image} alt="" />
          </div>
        )}
        {levelDownHeads ? (
          <h4 className={styles.headH4}>{title}</h4>
        ) : (
          <h3 className={styles.headH3}>{title}</h3>
        )}
        <div
          className={styles.paragraph}
          dangerouslySetInnerHTML={{ __html: shortDescription }}
        />
        {GetLink()}
      </USBColumn>
    </>
  )
}

export default OneColStacked
